<template>
  <div class="contontaab">
    <!-- 按钮 -->
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">按天查询</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 按年查询
      </a>
    </div>
    <div :style="{ width: '1000px', height: '350px' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        time: '',
        dfwCount: '', //待服务订单
        dplCount: '', //待评价订单
        dzfCount: '', //待支付订单
        jxzCount: '', //进行中订单
        ordersCount: '', //总订单
        yqxCount: '', //已取消订单
        ywcCount: '', //已完成订单
        ddzj: '',
        dynamic: 3,
        Timer: ''

      }
    },
    methods: {
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/user/selectCountOrders?page=' + 1 + '&limit=' + 5 + '&date=' + this.time + '&type=' + e, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            let newdata = [
              this.dfwCount = res.data.data.dfwCount, //待服务订单
              this.dplCount = res.data.data.dplCount, //待评价订单
              this.dzfCount = res.data.data.dzfCount, //待支付订单
              this.jxzCount = res.data.data.jxzCount, //进行中订单
              this.ordersCount = res.data.data.ordersCount, //总订单
              this.yqxCount = res.data.data.yqxCount, //已取消订单
              this.ywcCount = res.data.data.ywcCount, //已完成订单
            ]
            this.ddzj = [...newdata]
            this.getEchartData()
          }

        })
      },
      yearsday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/user/selectCountOrders?page=' + 1 + '&limit=' + 5 + '&date=' + this.time + '&type=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            let newdata = [
              this.dfwCount = res.data.data.dfwCount, //待服务订单
              this.dplCount = res.data.data.dplCount, //待评价订单
              this.dzfCount = res.data.data.dzfCount, //待支付订单
              this.jxzCount = res.data.data.jxzCount, //进行中订单
              this.ordersCount = res.data.data.ordersCount, //总订单
              this.yqxCount = res.data.data.yqxCount, //已取消订单
              this.ywcCount = res.data.data.ywcCount, //已完成订单
            ]
            this.ddzj = [...newdata]
            this.getEchartData()
          }

        })
      },
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);

        let chartData = {
          xData: [
            "待服务订单",
            "待评价订单",
            "待支付订单",
            "进行中订单",
            "总订单",
            "已取消订单",
            "已完成订单",
          ],
          data: this.ddzj,
          max: 100,
          color: [
            "#01DA99",
            "#00A7C0",
            "#0E468F",
            "#62AFE1",
            "#D66B30",
            "#D5AC52",
            "#D6C7A8",
          ],
          color2: [
            "#01CD91",
            "#009AB0",
            "#0D3F82",
            "#5BAADF",
            "#CC6228",
            "#CF9E38",
            "#CEBC97",
          ],
        };

        let effectScatterData = [];
        let bottomBarData = [];
        let topData = [];
        let middleData = [];
        let bgData = [];
        for (let i = 0; i < chartData.xData.length; i++) {
          //底部
          effectScatterData.push({
            name: "",
            value: "0",
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: chartData.color[i],
                  },
                  {
                    offset: 1,
                    color: chartData.color2[i],
                  },
                ]),
              },
            },
          });

          //下半截柱状图
          bottomBarData.push({
            name: "",
            value: chartData.data[i],
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  x2: 1,
                  y: 0,
                  y2: 0,
                  type: "linear",
                  global: false,
                  colorStops: [{
                      offset: 0,
                      color: chartData.color[i]
                    },
                    {
                      offset: 0.5,
                      color: chartData.color[i]
                    },
                    {
                      offset: 0.5,
                      color: chartData.color2[i]
                    },
                    {
                      offset: 1,
                      color: chartData.color2[i]
                    },
                  ],
                },
              },
            },
          });

          //下半部的顶
          middleData.push({
            name: "",
            value: chartData.data[i],
            symbolPosition: "end",
            label: {
              formatter: "{c}",
              color: "#fff",
              offset: [0, -5],
              show: true,
              position: "top",
              fontSize: 10,
              fontWeight: 400,
              fontFamily: "zcool-gdh",
            },
            itemStyle: {
              color: chartData.color[i],
            },
          });
          //头顶
          topData.push({
            name: "",
            value: chartData.max,
            symbolPosition: "end",

            itemStyle: {
              color: "#F4F4F4",
            },
          });
          // 背景
          bgData.push({
            name: "",
            value: chartData.max - chartData.data[i],
            symbolPosition: "end",
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  x2: 1,
                  y: 0,
                  y2: 0,
                  type: "linear",
                  global: false,
                  colorStops: [{
                      offset: 0,
                      color: "#dadada33"
                    },
                    {
                      offset: 0.5,
                      color: "#dadada33"
                    },
                    {
                      offset: 0.5,
                      color: "#dadada26"
                    },
                    {
                      offset: 1,
                      color: "#dadada26"
                    },
                  ],
                },
              },
            },
          });
        }

        var option = {
          grid: {
            top: "10%",
            left: "0%",
            right: "20%",
            bottom: "15%",
          },
          xAxis: {
            offset: 20,
            data: chartData.xData,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true, //是否全部显示
              interval: 0, //是否全部显示 （为0 是全部 为1不是）
              rotate: 0, //旋转
              textStyle: {
                color: "#A5C8E6",
                fontSize: 12,
              },
            },
          },
          yAxis: {
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
          series: [
            //'最低下,
            {
              name: "最低下的圆片",
              stack: "a",
              // type: 'pictorialBar',
              // symbolSize: [66, 12],
              // symbolOffset: [0, 6],
              type: "effectScatter",
              symbol: "diamond",
              symbolSize: [20, 12],
              symbolOffset: [0, 0],
              z: 22,
              data: effectScatterData,
            },
            //下半截柱状图
            {
              name: "下半截柱状图",
              stack: "a",
              type: "bar",
              barWidth: 20,
              z: 2,
              barGap: "-100%",
              data: bottomBarData,
            },
            //替代柱状图 默认不显示颜色
            {
              name: "替代柱状图",
              type: "bar",
              barWidth: 20,
              barGap: "-100%",
              stack: "b",
              itemStyle: {
                color: "transparent",
              },
              data: chartData.data,
            },
            //下半部的顶
            {
              name: "头部1",
              stack: "a",
              type: "pictorialBar",
              symbolSize: [20, 12],
              symbol: "diamond",
              symbolOffset: [0, -6],
              z: 22,
              data: middleData,
            },
            //顶部
            {
              name: "头部2",
              stack: "a",
              type: "pictorialBar",
              symbol: "diamond",
              symbolSize: [20, 12],
              symbolOffset: [0, -6],
              z: 22,
              data: topData,
            },
            //底色
            {
              name: "底色",
              type: "bar",
              barWidth: 20,
              z: 2,
              barGap: "-100%",
              stack: "b",
              data: bgData,
            },
          ],
        };


       let currentIndex = 0;
       clearInterval(this.timer);
       this.timer = setInterval(function() {
         // 取消之前高亮的图形
         myChart.dispatchAction({
           type: 'downplay',
           seriesIndex: 0,
         });
         // 高亮当前图形
         myChart.dispatchAction({
           type: 'highlight',
           seriesIndex: 0,
           dataIndex: currentIndex
       
         });
         // 显示 tooltip
         myChart.dispatchAction({
           type: 'showTip',
           seriesIndex: 0,
           dataIndex: currentIndex
       
         });
         currentIndex++;
         if (currentIndex > option.series[0].data.length) {
           currentIndex = 0;
         }
       }, 2000);
       myChart.clear()
       myChart.setOption(option, true);
      },

      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec;
      },
      // https://user.yunhejuzhen.com/sqx_fast/user/selectCountOrders?page=1&limit=5&date=2023-02-21+15:55:24&type=1
      selectCountOrders() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/user/selectCountOrders?page=' + 1 + '&limit=' + 5 + '&date=' + this.time + '&type=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            let newdata = [
              this.dfwCount = res.data.data.dfwCount, //待服务订单
              this.dplCount = res.data.data.dplCount, //待评价订单
              this.dzfCount = res.data.data.dzfCount, //待支付订单
              this.jxzCount = res.data.data.jxzCount, //进行中订单
              this.ordersCount = res.data.data.ordersCount, //总订单
              this.yqxCount = res.data.data.yqxCount, //已取消订单
              this.ywcCount = res.data.data.ywcCount, //已完成订单
            ]
            this.ddzj = [...newdata]
            this.getEchartData()
          }

        })
      }
    },
    mounted() {
      this.getEchartData()
      this.selectCountOrders()
      this.Timer = setInterval(this.selectCountOrders, 20000)
    },
    destroyed() {
      clearInterval(this.Timer)
    },
    created() {
      this.getDate()
    }
  }
</script>

<style scoped lang="less">
  .contontaab {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .query {
    width: 200px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 312px;
    bottom: 374px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .btn-secondary {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .active {
      background-color: lightskyblue;
    }
  }
</style>