<template>
  <div class="contontaa">

    <div class="echart1">
       <ONE></ONE>
    </div>
    <div class="echart2">
        <TWO></TWO>
    </div>
    <div class="echart3">
      <THREE></THREE>
    </div>

  </div>
</template>

<script>
  import ONE from "../leftecharts/leftetop.vue"
  import TWO from "../leftecharts/leftmiddle.vue"
  import THREE from "../leftecharts/leftbottom.vue"


  export default {
    components: {
      ONE,
      TWO,
      THREE

    },
    data() {
      return {

      }
    },
  }
</script>

<style scoped>
  .contontaa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .echart1 {
    width: 100%;
    height: 33.3%;
  }

  .echart2 {
    width: 100%;
    height: 33.3%;
  }

  .echart3 {
    width: 100%;
    height: 33.3%;
  }
</style>
