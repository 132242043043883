import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//引用ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
//引用echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

//引入字体
import "./assets/font/fontless.css"

//引入axios
import axios from 'axios'
Vue.prototype.$axios = axios //将axios绑定到vue的原型上

axios.defaults.baseURL = 'https://data.liyuejk.com/sqx_fast'// 使每次请求都会带一个 /api 前缀 
//axios.defaults.baseURL = 'https://data.quanmindaojia.com/sqx_fast' // 使每次请求都会带一个 /api 前缀 
//引用滚动
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

//引用词云
import wordcloud from 'echarts-wordcloud'
//粒子效果
import VueParticles from 'vue-particles'
Vue.use(VueParticles)


import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
