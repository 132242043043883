<template>
  <div class="contontaab">
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">按天查询</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 按年查询
      </a>
    </div>
    <div :style="{ width: '550px', height: '306px' }" ref="myChart"></div>
  </div>
</template>
<script>
  import {
    setInterval
  } from 'core-js/web';
  export default {
    data() {
      return {
        time: '', //时间
        jinMoney: '', //进行
        pingMoney: '', //平台收益
        sumMoney: '', //总收益
        wanMoney: '', //已完成
        xmshouyi: '',
        //传的参值
        day: '',
        years: '',
        dynamic: 3,
        Timer: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        const offsetX = 20;
        const offsetY = 10;
        // 绘制左侧面
        const CubeLeft = this.$echarts.graphic.extendShape({
          shape: {
            x: 0,
            y: 0,
          },
          buildPath: function(ctx, shape) {
            // 会canvas的应该都能看得懂，shape是从custom传入的
            const xAxisPoint = shape.xAxisPoint;
            // console.log(shape);
            const c0 = [shape.x, shape.y];
            const c1 = [shape.x - offsetX, shape.y - offsetY];
            const c2 = [xAxisPoint[0] - offsetX, xAxisPoint[1] - offsetY];
            const c3 = [xAxisPoint[0], xAxisPoint[1]];
            ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath();
          },
        });
        // 绘制右侧面
        const CubeRight = this.$echarts.graphic.extendShape({
          shape: {
            x: 0,
            y: 0,
          },
          buildPath: function(ctx, shape) {
            const xAxisPoint = shape.xAxisPoint;
            const c1 = [shape.x, shape.y];
            const c2 = [xAxisPoint[0], xAxisPoint[1]];
            const c3 = [xAxisPoint[0] + offsetX, xAxisPoint[1] - offsetY];
            const c4 = [shape.x + offsetX, shape.y - offsetY];
            ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath();
          },
        });
        // 绘制顶面
        const CubeTop = this.$echarts.graphic.extendShape({
          shape: {
            x: 0,
            y: 0,
          },
          buildPath: function(ctx, shape) {
            const c1 = [shape.x, shape.y];
            const c2 = [shape.x + offsetX, shape.y - offsetY]; //右点
            const c3 = [shape.x, shape.y - offsetX];
            const c4 = [shape.x - offsetX, shape.y - offsetY];
            ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath();
          },
        });
        // 注册三个面图形
        this.$echarts.graphic.registerShape('CubeLeft', CubeLeft);
        this.$echarts.graphic.registerShape('CubeRight', CubeRight);
        this.$echarts.graphic.registerShape('CubeTop', CubeTop);

        const VALUE = this.xmshouyi;

        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: function(params, ticket, callback) {
              const item = params[1];
              return item.name + ' : ' + item.value;
            },
          },
          grid: {
            left: '2%',
            right: '10%',
            top: '19%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: ['总收入', '平台收益', '服务中订单', '已完成订单'],
            axisLine: {
              show: true,
              lineStyle: {
                width: 2,
                color: '#2B7BD6',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 14,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                width: 2,
                color: '#2B7BD6',
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#153D7D',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 14,
              interval: 0
            },
            // boundaryGap: ['20%', '20%'],
          },
          series: [{
              type: 'custom',
              renderItem: (params, api) => {
                const location = api.coord([api.value(0), api.value(1)]);
                return {
                  type: 'group',
                  children: [{
                      type: 'CubeLeft',
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#33BCEB',
                          },
                          {
                            offset: 1,
                            color: '#337CEB',
                          },
                        ]),
                      },
                    },
                    {
                      type: 'CubeRight',
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#28A2CE',
                          },
                          {
                            offset: 1,
                            color: '#1A57B7',
                          },
                        ]),
                      },
                    },
                    {
                      type: 'CubeTop',
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#43C4F1',
                          },
                          {
                            offset: 1,
                            color: '#28A2CE',
                          },
                        ]),
                      },
                    },
                  ],
                };
              },
              data: VALUE,
            },
            {
              type: 'bar',
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  formatter: (e) => {
                    return e.value + '元';
                  },
                  fontSize: 16,
                  color: '#43C4F1',
                  offset: [0, -25],
                },
              },
              itemStyle: {
                color: 'transparent',
              },
              tooltip: {},
              data: VALUE,
            },
          ],
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec;
        //console.log(this.time)
      },
      OrdersMoney() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        //按年查询
        this.$axios.get('user/selectOrdersMoney?date=' + this.time + '&type=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            let data = res.data.data
            let newdata = [
              this.jinMoney = data.sumMoney, //进行
              this.pingMoney = data.pingMoney, //平台收益
              this.sumMoney = data.jinMoney, //总收益
              this.wanMoney = data.wanMoney, //已完成
            ]
            this.xmshouyi = [...newdata]
            this.getEchartData()
          }

        })
      },
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        //按年查询
        this.$axios.get('user/selectOrdersMoney?date=' + this.time + '&type=' + e, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            let data = res.data.data
            let newdata = [
              this.jinMoney = data.sumMoney, //进行
              this.pingMoney = data.pingMoney, //平台收益
              this.sumMoney = data.jinMoney, //总收益
              this.wanMoney = data.wanMoney, //已完成
            ]
            this.xmshouyi = [...newdata]
            this.getEchartData()
          }

        })
      },
      yearsday(e) {
        this.dynamic = e
        this.OrdersMoney()
      }

    },
    mounted() {
      this.getEchartData()
      this.OrdersMoney()
      this.Timer = setInterval(this.OrdersMoney, 20000)
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .contontaab {
    z-index: 999;
    width: 100%;
    height: 100%;
    position: relative;
    top: -0.6rem;
    left: -0.2rem;
  }

  .query {
    width: 150px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 354px;
    bottom: 276px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .btn-secondary {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .active {
      background-color: lightskyblue;
    }
  }
</style>