<template>
  <div class="lefr">
    <div class="lefr1">
      <div class="text">
        用户新增调查
      </div>
      <div class="kehugood">
        <div class="text">本月新增</div>
        <div class="num">{{kunum}}</div>
      </div>
    </div>
    <div class="lefr2">
      <zhuecharts></zhuecharts>
    </div>
  </div>
</template>

<script>
  import zhuecharts from "./echarts/zhuecharts.vue"
  import {
    Authorization
  } from "../../store/index.js"
  export default {
    components: {
      zhuecharts
    },
    data() {
      return {
        kunum: 2222,
        day: '',
        years: '',
        Timer: ''
      }
    },
    methods: {
      gethomeMessage() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get("/user/homeMessage", {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.kunum = res.data.data.newMonth
          }

        })
      },

    },
    created() {
      this.gethomeMessage()
      this.Timer = setInterval(this.gethomeMessage, 2000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped>
  .lefr {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
  }

  .lefr1 {
    width: 96%;
    height: 40%;
    background-image: url('../../assets/img/xzuo.png');
    background-repeat: no-repeat;
    background-position: 0px 73px;
    background-size: 100%;
  }

  .text {
    font-size: 32px;
    color: white;
    text-align: center;
  }

  .kehugood {
    width: 100%;
    height: 100%;
  }

  .kehugood .text {
    font-size: 27px;
    color: white;
    position: absolute;
    top: 61px;
    left: 40px;

  }

  .kehugood .num {
    font-size: 39px;
    color: skyblue;
    position: absolute;
    top: 52px;
    left: 369px;

  }

  .lefr2 {
    width: 100%;
    height: 60%;
  }
</style>