import { render, staticRenderFns } from "./zouamdeng.vue?vue&type=template&id=4bd25b45&scoped=true&"
import script from "./zouamdeng.vue?vue&type=script&lang=js&"
export * from "./zouamdeng.vue?vue&type=script&lang=js&"
import style0 from "./zouamdeng.vue?vue&type=style&index=0&id=4bd25b45&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd25b45",
  null
  
)

export default component.exports