<template>
  <div class="echarts">
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
  }
</script>

<style scoped>
</style>
