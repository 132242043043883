<template>
  <div class="contont" >
    <div class="text">
      技师用户信息
    </div>
     <div class="biao1">
       <div class="biao1_1">
         <Biaoone></Biaoone>
       </div>
      <div class="biao1_2">
         <Biaofour></Biaofour>
       </div>
     
     </div>
     <div class="biao2">
       <Biaotwo></Biaotwo>
     </div>
    <div class="biao3">
      <Biaothree></Biaothree>
     </div>
  </div>
</template>

<script>
  import Biaoone from "../rightecharts/shouyiecharts/biaoone.vue" 
   import Biaotwo from "../rightecharts/shouyiecharts/biaotwo.vue" 
    import Biaothree from "../rightecharts/shouyiecharts/biaothree.vue" 
     import Biaofour from "../rightecharts/shouyiecharts/biao4.vue"
  export default{
    components:{
      Biaoone,Biaotwo,Biaothree,Biaofour
    },
    data() {
      return{
        
      }
    }
  }
</script>

<style scoped>
  .contont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-image: url('../../assets/img/ciji3.png');
  }
  .text{
    font-size: 30px;
    color: white;
    text-align: center;
    line-height: 80px;
  }
  .biao1 {
    width: 100%;
    height: 33.3%;
    background-image: url('../../assets/img/cjij4.png');
    display: flex;
  }
  .biao1_1{
    width: 57%;
    height: 100%;
    border-right: 1px solid skyblue;
  }
  .biao1_2{
    width: 43%;
    height: 100%;
  }
  .biao2 {
    width: 100%;
    height: 33.3%;
    background-image: url('../../assets/img/cjij4.png');
  }
  .biao3 {
    width: 100%;
    height: 33.3%;
     background-image: url('../../assets/img/cjij4.png');
  }
</style>