<template>
  <div class="contont">
    <Bottom></Bottom>
  </div>
</template>

<script>
  import Bottom from "../leftecharts/echarts/echartsbottom.vue"
  export default{
    components:{Bottom},
    data() {
      return{
        
      }
    }
  }
</script>

<style scoped>
  .contont{
   width: 6rem;
   height: 100%;
  }
</style>