<template>
  <div id="app" style="z-index: 9999;">
    <div class="table_body">
      <div class="table_th">
        <div class="tr1 th_style">状态</div>
        <div class="tr2 th_style">电话</div>
        <div class="tr3 th_style">时间</div>
        <div class="tr4 th_style">推广者</div>
      </div>
      <vue-seamless-scroll :data="listData" :class-option="optionHover" class="seamless-warp">
        <div class="table_tr" v-for="(item,index) in listData" :key="index">
          <div class="tr1 tr">{{item.bianhao}}</div>
          <div class="tr2 tr">{{item.title}}</div>
          <div class="tr3 tr" v-if="item.startDate!='-'">{{item.date}}</div>
          <div class="tr3 tr" v-else>-</div>
          <div class="tr4 tr">{{item.name}}</div>
        </div>
      </vue-seamless-scroll>
    </div>

  </div>

</template>


<script>
  export default {
    data() {
      return {
        userNum: '',
        listData: [{
          'name': '客户1',
          'title': '技师服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }, {
          'name': '客户1',
          'title': '按摩服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }, {
          'name': '客户1',
          'title': '推油服务',
          'date': '2017-12-16'
        }, {
          'name': '客户1',
          'title': '家政服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }, {
          'name': '客户1',
          'title': '看狗服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }, {
          'name': '客户1',
          'title': 'xx服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }, {
          'name': '客户1',
          'title': 'xx服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }, {
          'name': '客户1',
          'title': 'xx服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }, {
          'name': '客户1',
          'title': 'xx服务',
          'date': '2017-12-16',
          'bianhao': '3131231'
        }]
      }
    },
    computed: {
      optionHover() {
        return {
          step: 0.5, // 数值越大速度滚动越快
          limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: false, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)

        };
      },

    },
    methods: {
      getuser() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/apply/selectApplyList?page=' + 1 + '&limit=' + 10 + '&applyName=&applyPhone=&classify=' + 1, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {

          let ress = res.data.data.list

          this.userNum = ress
          var arrNew = []
          arrNew = this.userNum.map((item) => {
            return {
              bianhao: item.applyContent, //状态
              name: item.applyName, //下单用户
              title: item.applyPhone, //技师用户
              date: item.createTime //价格
            }
          })
          this.listData = arrNew
          ///console.log(this.listData, '获取推广')
        })
      }
    },
    created() {
      this.getuser()
    }
  }
</script>
<style lang="less" scoped>
  .table_th {
    //头部
    width: 96%;
    display: flex;
    height: 64px;
    line-height: 60px;
  
  }
  
  .tr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 0.1125rem;
    text-align: center;
    font-size: 20px;
  }
  
  .tr1 {
    width: 23%;
  }
  
  .tr2 {
    width: 19%;
  }
  
  .tr3 {
    width: 35%;
    font-size: 20px;
  }
  
  .tr4 {
    flex: 1;
  
  }
  
  .th_style {
    color: rgb(0, 221, 253);
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 20px;
    text-align: center;
  }
  
  
  .seamless-warp {
    //整体的高
    height: 250px;
    overflow: hidden;
  
    ul li {
      list-style-type: none;
    }
  }
  
  .table_tr {
    display: flex;
    height: 50px;
    line-height: 50px;
    color: #eee;
    font-size: 20px;
    background: rgba(3, 145, 167, 0.1);
    border: 0.025rem solid rgb(4, 114, 131);
    border-right: none;
    border-left: none;
    margin-top: 0.375rem;
  }
  
  .el-table .cell {
    text-align: center;
  }
  
  .toptitle {
    width: 90%;
    display: flex;
    background-color: gainsboro;
    margin-bottom: 0.625rem;
    border: 0.125rem solid gainsboro;
    background-color: transparent;
    color: white
  }
  
  .item {
    width: 90%;
    text-align: center;
    position: relative;
    display: flex;
    line-height: 0.5rem;
    height: 0.5rem;
    color: #eee;
    background: rgba(3, 145, 167, 0.1);
    border: 0.0625rem solid rgb(4, 114, 131);
    margin-top: 0.4375rem;
  
    .date {
      width: 33%;
      position: absolute;
      left: -0.2rem;
      color: aqua;
    }
  
  }
</style>
