<template>
  <div class="contontaab">
    <div :style="{ width: '850px', height: '400px' }" ref="myChart"></div>
  </div>
</template>
<script>
  import wordCloud from "echarts-wordcloud"
  export default {
    data() {
      return {
        id: '',
        money: '',
        Timer: ''
      }
    },
    methods: {

      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        let xLabel = this.id
        let goToSchool = this.money
        let option = {
          tooltip: {
            trigger: 'axis',
            backgroundColor: '#FFF',
            borderColor: '#fff',

          },
          legend: {
            align: "left",
            right: '10%',
            top: '10%',
            textStyle: {
              color: '#F3FFFF',
              fontSize: 20
            },
            // icon:'rect',
            itemGap: 20,
            itemWidth: 20,
            itemHeight: 12,
          },
          grid: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '15%',
            containLabel: true
          },
          xAxis: [{

            type: 'category',
            boundaryGap: false,
            axisLine: { //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: '#233653'
              },
            },
            axisLabel: {
              interval: 3,
              rotate: 0, //坐标轴刻度标签的相关设置
              textStyle: {
                color: '#F3FFFF',
                padding: 14,
                fontSize: 15
              },
              formatter: function(data) {
                return data
              }
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: xLabel
          }],
          yAxis: [{
            name: '单位：元',
            nameTextStyle: {
              color: "#fff",
              fontSize: 14,
              padding: 10
            },
            min: 0,
            splitLine: {
              show: true,
              borderColor: "#fff",
              lineStyle: {
                color: '#404553'
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,

              textStyle: {
                color: '#F3FFFF',
                padding: 14,
                fontSize: 14
              },
              formatter: function(value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            axisTick: {
              show: false,
            },
          }],
          series: [{
            name: '价格￥',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: false,
            lineStyle: {
              normal: {
                width: 2,
                color: "rgba(25,163,223,1)", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "rgba(25,163,223,1)",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(25,163,223,.3)"
                  },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: goToSchool
          }]
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex
        
          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex
        
          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      getuser() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/artificer/selectMassageTypeList?status=' + 0, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            let jishi = res.data.data
            let newData = []
            //提取需要的数据
            jishi.map(i => {
              newData.push(Object.assign({
                value: i.artificerPrice
              }, {
                name: i.title,
              }))
            })
            let newarr = newData.map(item => {
              return item.name
            })
            this.id = newarr
            let newarra = newData.map(item => {
              return item.value
            })
            this.money = newarra
            this.getEchartData()
          }
        })
      }
    },
    mounted() {
      this.getEchartData()
      this.getuser()
      this.Timer = setInterval(this.getuser, 20000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped>
  .contontaab {
    width: 100%;
    height: 100%;
    position: relative;
    left: -0.2rem;
  }
</style>