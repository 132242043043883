<template>
  <div class="contontaab">
    <div class="text" style="text-align: center; color: white;">
      本年已完成订单
    </div>
    <div :style="{ width: '200px', height: '180px' }" ref="myChart"></div>
  </div>
</template>
<script>
  import {
    time
  } from 'echarts';
  export default {
    data() {
      return {
        newToday: '',
        time: '',
        Timer: ''
      }
    },
    methods: {

      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {

          series: [{
            name: 'Pressure',
            type: 'gauge',
            startAngle: 160, //整体长度
            endAngle: 20, //右边
            splitNumber: 5, //显示数字多少
            min: 0,
            max: 100,
            center: ['52%', '60%'],
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: '#FF6B00'
                  },
                  {
                    offset: 1,
                    color: '#F1BF4E'
                  }
                ])
              }
            },
            progress: {
              show: true,
              width: 30
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 30 //表的宽度
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: true,
              distance: -39, //数字的距离
              color: '#6587B3'
            },
            detail: {
              show: true
            },
            data: [{
              value: this.newToday
            }]
          }]
        };

       let currentIndex = 0;
       clearInterval(this.timer);
       this.timer = setInterval(function() {
         // 取消之前高亮的图形
         myChart.dispatchAction({
           type: 'downplay',
           seriesIndex: 0,
         });
         // 高亮当前图形
         myChart.dispatchAction({
           type: 'highlight',
           seriesIndex: 0,
           dataIndex: currentIndex
       
         });
         // 显示 tooltip
         myChart.dispatchAction({
           type: 'showTip',
           seriesIndex: 0,
           dataIndex: currentIndex
       
         });
         currentIndex++;
         if (currentIndex > option.series[0].data.length) {
           currentIndex = 0;
         }
       }, 2000);
       myChart.clear()
       myChart.setOption(option, true);
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec;
      },
      gethomeMessage() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/user/selectCountOrders?page=' + 1 + '&limit=' + 5 + '+&date=' + this.time + '&type=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.newToday = res.data.data.ywcCount
            this.getEchartData()
          }

        })
      }
    },
    mounted() {
      this.getEchartData()
      this.gethomeMessage()
      this.getDate()
      this.Timer = setInterval(this.gethomeMessage, 200000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped>
  .contontaab {
    z-index: 999;
    position: relative;
  }

  .text {
    width: 100%;
    background-image: url('../../../assets/img/bbj.png');
    font-size: 20px;
  }
</style>