<template>
  <div class="contion">
    <vue-particles color="#FF6600" :particleOpacity="0.7" :particlesNumber="80" shapeType="star" :particleSize="4"
      linesColor="#FF6600" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="3"
      :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="particles-js" />
    <div class="title">
      <h1>欢迎登录</h1>
    </div>
    <div class="box">
      <el-tabs type="border-card" class="top">
        <div class="boxx">
          <div class="texttt">
          <div>中泰到家数据面板</div>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户名" prop="name">
              <el-input type="text" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="yzm">
              <el-input type="text" v-model="ruleForm.yzm"></el-input>
              <img :src="yzmimg" alt="" class="yzmimg" @click="getagain()">
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="login" @keyup.enter="keyDown(e)">登录</el-button>
              <el-button @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tabs>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('验证码不能为空'));
        }
      };
      var validateuser = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入用户名'));
        }
      };
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入密码'));
        }
      };
      return {
        ruleForm: {
          password: '',
          name: '',
          yzm: ''
        },
        yzmimg: '',
        uuidA: '',
        // 延时器的 timerId
        timer: null,
        rules: {
          password: [{
            validator: validatePass,
            trigger: 'blur'
          }],
          name: [{
            validator: validateuser,
            trigger: 'blur'
          }],
          yzm: [{
            validator: checkAge,
            trigger: 'blur'
          }]
        }
      };
    },
    methods: {
      login() {
        //获取
        if (this.ruleForm.name == '' || this.ruleForm.password == '' || this.ruleForm.yzm == '') {
          this.$message.error('请先填写用户名，密码，验证码在进行登录')
          return
        }
        this.$axios.post("/sys/login", {
          username: this.ruleForm.name,
          password: this.ruleForm.password,
          uuid: this.uuidA,
          captcha: this.ruleForm.yzm
        }).then((res) => {
          if(res.data.code==500){
            this.$message.error('用户名或密码不正确')
          }
         window.sessionStorage.setItem("Authorization", res.data.token);
         this.$router.push("/home");
        });
      },
      keyDown(e) {
        if (e.keyCode == 13) {
          if (this.ruleForm.name == '' || this.ruleForm.password == '' || this.ruleForm.yzm == '') {
            this.$message.error('请先填写用户名，密码，验证码在进行登录')
            return
          } else {
            this.login()
          }
        }
      },
      reset() {
        this.ruleForm.name = '',
          this.ruleForm.password = '',
          this.ruleForm.yzm = ''
      },
      uuid() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = "-";
        this.uuidA = s.join("");
      },
      //页面展示是显现
      yanzhenma() {
        this.$axios.get('/captcha.jpg?uuid=' + this.uuidA, {
          responseType: 'arraybuffer' // 最为关键
        }).then((res) => {
          this.yzmimg = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(res.data)
        })
      },
      //点击按钮时显示
      getagain() {
        this.uuid();
        this.$axios.get('/captcha.jpg?uuid=' + this.uuidA, {
          responseType: 'arraybuffer' // 最为关键
        }).then((res) => {
          this.yzmimg = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(res.data)
        })
      },

      arrayBufferToBase64(buffer) {
        var binary = ''
        var bytes = new Uint8Array(buffer)
        var len = bytes.byteLength
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
      }
    },
    mounted() {
      // this.login()
      this.uuid();
      this.yanzhenma();
      //绑定事件
      window.addEventListener("keydown", this.keyDown);
    }
  }
</script>
<style scoped lang="less">
  .top {
    width: 450px;
    height: 350px;
    margin: auto;

    .texttt {
      width: 400px;
      margin: auto;
      text-align: center;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 20px;

    }
  }

  .boxx {
    width: 70%;
    height: 300px;
    /* background-color: antiquewhite; */
    margin-top: 30px;

  }

  .title {
    width: 200px;
    margin: auto;
  }

  .title h1 {
    font-size: 40px;
  }

  .yzmimg {
    width: 100px;
    position: absolute;
    top: 5px;
    left: 220px;
    border-radius: 5px;
    height: 33px;

  }

  .particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
  }
</style>
