<template>
  <div class="contontaab">
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">按天查询</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 按年查询
      </a>
    </div>
    <div :style="{ width: '550px', height: '306px' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        type: 1,
        time: '', //时间
        appCount: '', //App用户
        jiUserCount: '', //技师用户
        phoneUserCount: '', //手机用户
        puUserCount: '', //普通用户
        userCount: '', //总用户
        wapCount: '', //小程序用户
        wxCount: '', //微信用户
        userALL: '',
        //传的参值
        day: '',
        years: '',
        dynamic: 3,
        Timer: ''
      }
    },
    methods: {
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/user/selectUserMessage?date=' + this.time + '&type=' + e, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            var data = res.data.data
            const user = [
              this.appCount = data.appCount, //App用户
              this.jiUserCount = data.jiUserCount, //技师用户
              this.phoneUserCount = data.phoneUserCount, //手机用户\
              this.puUserCount = data.puUserCount, // 普通用户
              this.userCount = data.userCount, //总用户
              this.wapCount = data.wapCount, //小程序用户
              this.wxCount = data.wxCount, //微信用户
            ]
            let all = [...user]
            this.userALL = all
            this.getEchartData()
          }

        })

      },
      yearsday(e) {
        this.dynamic = e
        this.getuserhome()
      },

      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        let xaxisData = ['App用户', '技师用户', '手机用户', '普通用户', '总用户', '小程序用户', '微信用户']
        let yaxisData = this.userALL

        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            backgroundColor: "rgba(255,255,255,0.75)",
            extraCssText: "box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.3);",
            textStyle: {
              fontSize: 14,
              color: "#000",
            },
            formatter: (params) => {
              const item = params[0];
              return item.name + " : " + item.value + " 人";
            },
          },
          grid: {
            left: "2%",
            right: "10%",
            top: "10%",
            bottom: "34%",
            containLabel: true,
          },
          xAxis: [{
            type: "category",
            axisLabel: {
              interval: 0,
              color: "#fff",
              fontSize: 14,
            },
            axisLine: {
              lineStyle: {
                //y轴网格线设置
                color: "rgba(0, 168, 255, 0.4)",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            data: xaxisData,
          }, ],
          yAxis: [{
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#fff",
              fontWeight: 400,
              fontSize: 18,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 168, 255, 0.3)",
                width: 1,
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              fontSize: 18,
              color: "#fff",
            },
          }, ],
          series: [{
            type: "bar",
            barWidth: 16,
            label: {
              show: true,
              position: "top",
              color: "#00A8FF",
            },
            itemStyle: {
              borderRadius: [8, 8, 0, 0],
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "#00A8FF",
                },
                {
                  offset: 1,
                  color: "rgba(0, 122, 255, 0)",
                },
              ]),
            },
            data: yaxisData,
          }, ],
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      getuserhome() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/user/selectUserMessage?date=' + this.time + '&type=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.coe == 401) {
            this.$router.push('/')
              
          } else {
            var data = res.data.data
            const user = [
              this.appCount = data.appCount, //App用户
              this.jiUserCount = data.jiUserCount, //技师用户
              this.phoneUserCount = data.phoneUserCount, //手机用户\
              this.puUserCount = data.puUserCount, // 普通用户
              this.userCount = data.userCount, //总用户
              this.wapCount = data.wapCount, //小程序用户
              this.wxCount = data.wxCount, //微信用户
            ]
            let all = [...user]
            this.userALL = all
            this.getEchartData()
          }

        })

      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec;
      }

    },
    mounted() {
      this.getEchartData()
      this.getuserhome()
      this.Timer = setInterval(this.getuserhome, 20000)
    },
    destroyed() {
      clearInterval(this.Timer)
    },
    created() {
      this.getDate()
    }
  }
</script>

<style scoped lang="less">
  .contontaab {
    z-index: 999;
    width: 100%;
    height: 100%;
    position: relative;
    top: -0.6rem;
    left: -0.2rem;
  }

  .query {
    width: 150px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 354px;
    bottom: 169px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .btn-secondary {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .active {
      background-color: lightskyblue;
    }
  }
</style>