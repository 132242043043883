import { render, staticRenderFns } from "./bingecharts3.vue?vue&type=template&id=923f4b2c&scoped=true&"
import script from "./bingecharts3.vue?vue&type=script&lang=js&"
export * from "./bingecharts3.vue?vue&type=script&lang=js&"
import style0 from "./bingecharts3.vue?vue&type=style&index=0&id=923f4b2c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "923f4b2c",
  null
  
)

export default component.exports