<template>
  <div class="lefr">
    <div class="lefr1">
      <div class="kehugood">
        <div class="text">投诉待处理</div>
        <div class="num">{{kunum}}</div>
      </div>
    </div>
    <div class="lefr2">
      <div class="bing1">
        <bingecharts></bingecharts>
      </div>
      <div class="bing2" @click="onpageshow()" style="z-index: 999;">
        <bingecharts2></bingecharts2>
      </div>
      <div class="bing3">
        <bingecharts3></bingecharts3>
      </div>
      <div class="bing4">
        <bingecharts4></bingecharts4>
      </div>
      <div class="bing5">
        <bingecharts5></bingecharts5>
      </div>
      <div class="bing6">
        <bingecharts6></bingecharts6>
      </div>

    </div>
    <div class="show" v-if="pageshow">
      <div class="down" @click="onkeydown">
        <img src="../../assets/down.png" />
      </div>
      <btvue></btvue>
    </div>
  </div>
</template>

<script>
  import bingecharts from "./echarts/bingmiddle/bingecharts.vue"
  import bingecharts2 from "./echarts/bingmiddle/bingecharts2.vue"
  import bingecharts3 from "./echarts/bingmiddle/bingecharts3.vue"
  import bingecharts4 from "./echarts/bingmiddle/bingecharts4.vue"
  import bingecharts5 from "./echarts/bingmiddle/bingecharts5.vue"
  import bingecharts6 from "./echarts/bingmiddle/bingecharts6.vue"
  import btvue from "../leftecharts/btshow/btshow.vue"

  export default {
    components: {
      bingecharts,
      bingecharts2,
      bingecharts3,
      bingecharts4,
      bingecharts5,
      btvue,
      bingecharts6

    },
    data() {
      return {
        kunum: 2222,
        pageshow: false,
        Timer: ''
      }
    },
    methods: {
      onpageshow() {
        this.pageshow = !this.pageshow
      },
      onkeydown() {
        this.pageshow = false;
      },
      selectMessageCount() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/message/selectMessageCount?state=' + 7, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.kunum = res.data.data
          }

        })
      }

    },
    created() {
      this.selectMessageCount()
      this.Timer = setInterval(this.selectMessageCount, 2000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .lefr {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
  }

  .lefr1 {
    width: 100%;
    height: 40%;
    margin-bottom: 1.05rem;
    background-image: url('../../assets/img/xzuo.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .text {
    font-size: 32px;
    color: white;
    text-align: center;
  }

  .kehugood {
    width: 100%;
    height: 100%;
  }

  .kehugood .text {
    font-size: 27px;
    color: white;
    position: absolute;
    top: 61px;
    left: 40px;

  }

  .kehugood .num {
    font-size: 39px;
    color: skyblue;
    position: absolute;
    top: 52px;
    left: 369px;
  }

  .lefr2 {
    width: 100%;
    height: 60%;
    display: flex;
    position: absolute;
    left: -1px;
    top: 90px;

  }

  .lefr2 .bing1 {
    width: 67px;
  }

  .lefr2 .bing2 {
    width: 67px;
  }

  .lefr2 .bing3 {
    width: 67px;
  }

  .lefr2 .bing4 {
    width: 67px;
  }

  .lefr2 .bing5 {
    width: 67px;
  }

  .lefr2 .bing6 {
    width: 67px;
  }

  .show {
    width: 520px;
    height: 300px;
    position: absolute;
    top: -18%;
    left: 134%;
    z-index: 999;
    background-image: url('../../assets/img/cjij4.png');
    background-color: skyblue;
    opacity: 0.9;
    // background-image: url('../../assets/show.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    img {
      width: 0.3rem;
      height: 0.3rem;
      position: absolute;
      right: 0;
    }
  }
</style>